import styled from "@emotion/styled"
import React from "react"
import { Layout, Page } from "../components/layout"
import { SEO } from "../components/seo"
import { theme } from "../theme"

const NotFoundPage = styled(Page)`
  footer small {
    color: ${theme.colors.text};
  }
`

const Content = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    color: ${theme.colors.text};
  }
`

const NotFound = () => {
  return (
    <NotFoundPage>
      <Layout>
        <SEO title="404" />

        <Content>
          <header>
            <h1>Not Found</h1>
          </header>
        </Content>
      </Layout>
    </NotFoundPage>
  )
}

export default NotFound
